<template>
    <div class="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8">
        <div>
            <article class="news-box">
                <h3>Online-Sportangebote aus den Standorten</h3>
                <span>Bad Windsheim:Max und Shaheera machen bis zum 04.05.21 weiter,...</span><br/>
                <button class="btn">News & Aktuelles</button>
            </article>
        </div>
        <div>
            <article class="news-box">
                <h3>Alle Schulungsangebote auf einen Blick</h3>
                <span>Katalog für Mitarbeitende im Gesundheitswesen.</span><br/>
                <button class="btn">News & Aktuelles</button>
            </article>
        </div>
        <div>
            <article class="news-box">
                <h3>Corona-Führerschein</h3>
                <span>Hier gelangen Sie als Vorbereitung auf Ihre Reha zum Corona-Führerschein.</span><br/>
                <button class="btn">Zum Corona Führerschein</button>
            </article>
        </div>
    </div>
</template>

<script>
export default {
}
</script>
